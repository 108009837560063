import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { FormattedMessage } from "react-intl"
import Layout from "../components/Layout"
import SilverText from "../components/SilverText"
import TechTag from "../components/TechTag"
import DownloadButton from "../components/DownloadButton"
import BlockTitle from "../components/BlockTitle"

import "../components/List.css"

import AustraBg from "../images/austra-modules-bg@2x.png"
import AustraAlternative1 from "../images/austra-alternative-1.svg"
import AustraAlternative2 from "../images/austra-alternative-2.svg"
import DesignCirclePartImage from "../images/mask@3x.png"
import AppDevThumbnail from "../components/thumbnails/AppDevThumbnail"
import SoftDevThumbnail from "../components/thumbnails/SoftDevThumbnail"
import UiUxThumbnail from "../components/thumbnails/UiUxThumbnail"

const SubtitleWithList = ({ title, items }) => (
  <>
    <h4 className="text-gray-700 font-semibold my-6">{title}</h4>
    <ul className="text-gray-700 list-default leading-8 pl-5">
      {items.map((item, i) => (
        <li key={i} className="pl-4">
          {item}
        </li>
      ))}
    </ul>
  </>
)

const CaseStudyTemplate = ({
  title,
  mainDescriptionWithoutName,
  zetaUrl,
  listImage,
  challengeTitlePrefix,
  challengeTitle,
  challengeAlternativeTitlePrefix1,
  challengeAlternativeTitle1,
  challengeAlternative1AdvantageItems,
  challengeAlternative1DisadvantageItems,
  challengeAlternativeTitlePrefix2,
  challengeAlternativeTitle2,
  challengeAlternative2AdvantageItems,
  challengeAlternative2DisadvantageItems,
  challengeAlternativeAdvantageTitle,
  challengeAlternativeDisadvantageTitle,
  solutionTitlePrefix,
  solutionTitle,
  solutionDescription,
  solutionImage,
  solutionSubtitle,
  solutionDescription2,
  solutionImage2,
  solutionSubtitle2,
  solutionDescription3,
  resultTitle,
  resultImage,
  additionalInfoTitle,
  generalPresentation,
  generalPresentationContentPath,
  technicalPresentation,
  technicalPresentationContentPath,
  resultItems,
  helmet,
}) => {
  return (
    <div>
      {helmet || ""}

      {/* FOLD BLOCK */}
      <div className="relative lg:flex container mx-auto py-12 pb-4 px-10 lg:px-32">
        <img
          src={DesignCirclePartImage}
          alt=""
          className="absolute w-24 transform rotate-180"
          style={{ left: "40%", top: "5px" }}
        />
        <img
          src={DesignCirclePartImage}
          alt=""
          className="absolute w-48"
          style={{ right: "6rem", bottom: "-3rem" }}
        />
        <div className="lg:w-1/3">
          <h1 className="font-semibold text-6xl tracking-wide text-gray-900 leading-none lg:pr-40">
            {title}
          </h1>
          <p className="font-light text-lg text-gray-500 mt-10">
            {mainDescriptionWithoutName}{" "}
            <a
              href={zetaUrl}
              className="font-semibold text-green-500 hover:text-green-800"
            >
              Zeta Industry
            </a>
          </p>
          <p className="mt-10">
            <TechTag>Ruby on Rails</TechTag>
            <TechTag>React Native</TechTag>
          </p>
        </div>
        <div className="lg:w-2/3">
          <img
            src={listImage.childImageSharp.fixed.src}
            alt=""
            className="-mb-8"
          />
        </div>
      </div>

      {/* CHALLENGE BLOCK */}
      <div className="" style={{ backgroundColor: "#f2f2f2" }}>
        <div className="relative container mx-auto py-24 px-10 lg:pl-20 lg:pr-32">
          <BlockTitle>
            <h2 className="font-semibold text-3xl mb-20">
              <span className="text-green-500">{challengeTitlePrefix}</span>{" "}
              {challengeTitle}
            </h2>
          </BlockTitle>

          <div className="md:pl-12">
            <div className="lg:pl-8 flex flex-col">
              <div className="mb-12 lg:flex lg:flex-row items-center">
                <img src={AustraAlternative1} alt="" className="mr-20 h-32" />
                <div className="flex flex-col md:mr-24 ml-2">
                  <h3 className="font-semibold mb-4">
                    <span className="text-green-500">
                      {challengeAlternativeTitlePrefix1}
                    </span>{" "}
                    {challengeAlternativeTitle1}
                  </h3>
                  <SubtitleWithList
                    title={challengeAlternativeAdvantageTitle}
                    items={challengeAlternative1AdvantageItems}
                  />
                  <SubtitleWithList
                    title={challengeAlternativeDisadvantageTitle}
                    items={challengeAlternative1DisadvantageItems}
                  />
                </div>
              </div>
              <div className="lg:flex lg:flex-row items-center">
                <img src={AustraAlternative2} alt="" className="mr-20 h-32" />
                <div className="flex flex-col md:mr-24">
                  <h3 className="font-semibold mb-4">
                    <span className="text-green-500">
                      {challengeAlternativeTitlePrefix2}
                    </span>{" "}
                    {challengeAlternativeTitle2}
                  </h3>
                  <SubtitleWithList
                    title={challengeAlternativeAdvantageTitle}
                    items={challengeAlternative2AdvantageItems}
                  />
                  <SubtitleWithList
                    title={challengeAlternativeDisadvantageTitle}
                    items={challengeAlternative2DisadvantageItems}
                  />
                </div>
              </div>
            </div>
            <img
              src={DesignCirclePartImage}
              alt=""
              className="absolute w-32 transform rotate-90"
              style={{ left: "0", bottom: "-4rem" }}
            />
          </div>
        </div>
      </div>

      {/* SOLUTION BLOCK */}
      <div className="container mx-auto pt-24 pb-0 px-10 lg:pl-20 lg:pr-32">
        <BlockTitle>
          <h2 className="font-semibold text-3xl mb-8">
            <span className="text-green-500">{solutionTitlePrefix}</span>{" "}
            {solutionTitle}
          </h2>
        </BlockTitle>

        <div className="md:pl-12">
          <SilverText>{solutionDescription}</SilverText>

          <div className="relative my-24">
            <img
              src={AustraBg}
              alt=""
              className="w-56 absolute"
              style={{ left: "-5rem", bottom: "0" }}
            />
            <div
              className="absolute"
              style={{
                width: "670px",
                height: "670px",
                opacity: "0.3",
                border: "solid 40px #3aaf8e",
                backgroundColor: "#3aaf8e",
                borderRadius: "500px",
                top: "-3rem",
                right: "3rem",
              }}
            ></div>
            <img
              src={solutionImage.childImageSharp.fixed.src}
              alt=""
              className="relative"
            />
          </div>

          <div className="flex flex-row my-24">
            <div className="w-1/3">
              <img
                src={solutionImage2.childImageSharp.fixed.src}
                alt=""
                className="w-32 mx-auto"
              />
            </div>
            <div className="flex flex-col w-2/3">
              <div>
                <h3 className="font-semibold mb-4">{solutionSubtitle}</h3>
                <SilverText>{solutionDescription2}</SilverText>
              </div>
              <div>
                <h3 className="font-semibold mt-12 mb-4">
                  {solutionSubtitle2}
                </h3>
                <SilverText>{solutionDescription3}</SilverText>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ADDITIONAL INFO BLOCK */}
      <div className="container mx-auto py-24 px-10 lg:pl-20 lg:pr-32 md:pb-64">
        <BlockTitle>
          <h2 className="font-semibold text-3xl mb-16">
            {additionalInfoTitle}
          </h2>
        </BlockTitle>

        <div className="flex flex-col md:flex-row items-center md:items-baseline pl-0 md:pl-12">
          <DownloadButton
            title={generalPresentation}
            to={generalPresentationContentPath}
            className="mb-6 md:mr-8"
          />
          <DownloadButton
            title={technicalPresentation}
            to={technicalPresentationContentPath}
          />
        </div>
      </div>

      {/* RESULT BLOCK */}
      <div className="" style={{ backgroundColor: "#161b28" }}>
        <div className="container mx-auto py-20 px-10 lg:px-32 md:flex">
          <div className="sm:w-1/3 lg:w-1/2">
            <h2 className="text-white font-semibold text-3xl mb-8">
              {resultTitle}
            </h2>
            <ul className="text-white list-default leading-9 pl-8">
              {resultItems.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </div>
          <div className="relative sm:w-2/3 lg:w-1/2">
            <img
              src={resultImage.childImageSharp.fixed.src}
              alt=""
              className="md:absolute md:ml-24 right-16 lg:right-auto"
              style={{ width: "22rem", top: "-10rem" }}
            />
          </div>
        </div>
      </div>

      {/* USED TECHNOLOGIES BLOCK */}
      <div className="container mx-auto py-32 px-10 md:px-32">
        <h3 className="text-center font-semibold text-2xl mb-12">
          <FormattedMessage id="case_study.tech_used" values={{ app: title }} />
        </h3>
        <div className="md:flex md:flex-row justify-center">
          <AppDevThumbnail className="md:w-1/3" />
          <SoftDevThumbnail className="md:w-1/3" />
          <UiUxThumbnail className="md:w-1/3" />
        </div>
      </div>
    </div>
  )
}

const AustraErpCaseStudy = ({ data, location }) => {
  const { markdownRemark: project } = data

  return (
    <Layout location={location}>
      <CaseStudyTemplate
        helmet={
          <Helmet>
            <title>{`${project.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${project.frontmatter.mainDescription}`}
            />
          </Helmet>
        }
        {...project.frontmatter}
      />
    </Layout>
  )
}

export default AustraErpCaseStudy

export const pageQuery = graphql`
  query austraProjectByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        mainDescription
        mainDescriptionWithoutName
        zetaUrl
        listImage {
          childImageSharp {
            fixed(width: 1000, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        challengeTitlePrefix
        challengeTitle
        challengeAlternativeTitlePrefix1
        challengeAlternativeTitle1
        challengeAlternative1AdvantageItems
        challengeAlternative1DisadvantageItems
        challengeAlternativeTitlePrefix2
        challengeAlternativeTitle2
        challengeAlternative2AdvantageItems
        challengeAlternative2DisadvantageItems
        challengeAlternativeAdvantageTitle
        challengeAlternativeDisadvantageTitle
        solutionTitlePrefix
        solutionTitle
        solutionDescription
        solutionImage {
          childImageSharp {
            fixed(width: 1600, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        solutionSubtitle
        solutionDescription2
        solutionImage2 {
          childImageSharp {
            fixed(width: 240, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        solutionSubtitle2
        solutionDescription3
        resultTitle
        resultImage {
          childImageSharp {
            fixed(width: 660, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        additionalInfoTitle
        generalPresentation
        generalPresentationContentPath
        technicalPresentation
        technicalPresentationContentPath
        resultItems
      }
    }
  }
`
