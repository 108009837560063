import React from "react"

import DownloadIcon from "../images/download.svg"

const DownloadButton = ({ title, to = "#", className = "" }) => (
  <a
    className={`flex flex-row items-center bg-green-500 hover:bg-green-800 px-6 ${className}`}
    href={to}
  >
    <span className="text-white lg:text-lg font-semibold py-4 w-64 md:w-40 lg:w-auto">
      {title}
    </span>
    <img src={DownloadIcon} alt="" className="bg-white ml-8" />
  </a>
)

export default DownloadButton
